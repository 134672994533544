<template>
    <div v-if="artikler">
        <v-row style="margin-left: -24px">
            <template v-for="(artikkel, index) in artikler">
                <v-col :cols="12 / cols" :key="index">
                    <v-card flat :to="'/aktuelt/' + artikkel.id" class="v-card--article">
                        <v-img v-if="artikkel.bilde" :aspect-ratio="16 / 9" :cover="true" :src="bilder[artikkel.id]" style="margin: 16px 16px 0 16px"></v-img>
                        <v-card-title>
                            <span>{{ artikkel.tittel }}</span>
                        </v-card-title>
                        <v-card-text class="text--primary">
                            <div v-if="artikkel.publisert" class="text-caption">
                                <span>{{ artikkel.publisert | format('DD.MM.YYYY') }}</span>
                            </div>
                            <div v-html="artikkel.ingress"></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
//import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'ArticleList',
    props: {
        artikler: {
            type: Array,
            required: true,
        },
        cols: {
            type: Number,
            default: 2,
        },
    },
    data: function () {
        const bilder = {};
        for (const artikkel of this.artikler) {
            bilder[artikkel.id] = '';
        }
        return {
            bilder: bilder,
        };
    },
    computed: {},
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },

    /**
     * created
     */
    created: async function () {
        for (const artikkel of this.artikler) {
            if (artikkel.bilde) {
                this.bilder[artikkel.id] = await this.imageRequest({
                    method: 'get',
                    url: '/artikkel/' + artikkel.id + '/bilde',
                });
            }
        }
    },
    methods: {
        ...mapActions('api', ['imageRequest']),
    },
};
</script>
