<template>
    <v-dialog v-model="dialog" width="500" transition="dialog-top-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Månedsrapport</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <br />
                <p class="text-subtitle-1" v-if="rapport.gruppe">
                    <strong>Rapport for sorgruppe {{ rapport.gruppe.lopenr }} - {{ rapport.dato | format('MMMM YYYY') }}</strong>
                </p>

                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-select
                        v-if="!innloggetBruker && !slettetFrivillig"
                        v-model="frivilligId"
                        label="Frivillig"
                        :rules="rules.frivilligId"
                        :items="frivillige"
                        item-text="fulltNavn"
                        item-value="id"
                        :readonly="readonly"
                    ></v-select>
                    <v-text-field
                        v-model="rapport.antallMoter"
                        label="Hvor mange møter har dere hatt denne måneden?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallDeltakerePrMote"
                        label="Hvor mange deltakere per møte?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallAktiveDeltakere"
                        label="Hvor mange aktive deltakere har dere i gruppen?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallNyeDeltakere"
                        label="Hvor mange nye deltakere denne måneden?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallDeltakereSluttet"
                        label="Hvor mange deltakere sluttet denne måneden?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallDeltakereIPermisjon"
                        label="Hvor mange deltakere er i permisjon denne måneden?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallLedigePlasser"
                        label="Hvor mange ledige plasser er det i gruppen?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallTimer"
                        label="Hvor mange timer har du brukt som frivillig denne måneden? (Reising ikke inkludert)"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-textarea v-model="rapport.kommentar" label="Er det noe du ønsker å tilføye?" :readonly="readonly"></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <template v-if="!readonly">
                    <v-btn text v-on:click="dialog = false">Avbryt</v-btn>
                    <v-btn v-on:click="save" color="primary" v-text="rapport.levert ? 'Lagre' : 'Levèr'"></v-btn>
                </template>
                <v-btn v-else v-on:click="dialogrea = false">Lukk</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    name: 'VisitDialogReport',
    props: {
        rapporter: {
            type: Array,
            required: true,
        },
        innloggetBruker: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rapport: {},
            frivilligId: null,
            frivillige: null,

            dialog: false,
            index: -1,
            valid: false,
            readonly: true,

            rules: {
                frivilligId: [(v) => !!v || 'Frivillig må oppgis'],
            },
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
        slettetFrivillig: function () {
            return (this.rapport.frivillig && this.rapport.frivillig.arkivert) || false;
        },
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    methods: {
        ...mapActions('api', ['request', 'updateReports']),
        ...mapActions(['snackbar']),

        /**
         * edit
         */
        edit: function (index) {
            this.rapport = Object.assign({}, this.rapporter[index]);
            this.frivilligId = this.rapport.frivillig ? this.rapport.frivillig.id : null;
            this.frivillige = [];
            if (!this.innloggetBruker && !this.slettetFrivillig) {
                if (this.rapport.gruppe) {
                    for (const leder of this.rapport.gruppe.ledere) {
                        if (!leder.fjernet) {
                            this.frivillige.push(leder.person);
                        }
                    }
                }
            }

            this.readonly = false;
            if (!this.hasRole('ANSATT')) {
                if (this.rapport.levert) {
                    const daysSinceDelivered = moment().diff(this.rapport.levert, 'd');
                    if (daysSinceDelivered > 10) {
                        this.readonly = true;
                    }
                }
            }

            this.index = index;
            this.dialog = true;
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                try {
                    document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
                } catch (e) {
                    console.log(e);
                }
            } else {
                const rapport = Object.assign({}, this.rapport);
                const today = new moment();

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/rapportskjema/' + rapport.id + '/lever',
                    data: {
                        frivilligId: this.innloggetBruker ? this.user.id : this.frivilligId,
                        antallMoter: rapport.antallMoter,
                        antallDeltakerePrMote: rapport.antallDeltakerePrMote,
                        antallAktiveDeltakere: rapport.antallAktiveDeltakere,
                        antallNyeDeltakere: rapport.antallNyeDeltakere,
                        antallDeltakereSluttet: rapport.antallDeltakereSluttet,
                        antallDeltakereIPermisjon: rapport.antallDeltakereIPermisjon,
                        antallLedigePlasser: rapport.antallLedigePlasser,
                        antallKoblinger: rapport.antallKoblinger,
                        antallTimer: rapport.antallTimer,
                        kommentar: rapport.kommentar,
                        levert: today.format('DD.MM.YYYY'),
                    },
                });

                this.dialog = false;
                this.rapporter[this.index] = response;

                this.rapporter[this.index].levert = moment();

                if (this.innloggetBruker) {
                    await this.updateReports();
                    this.snackbar('Månedsrapport er levert.');
                }
                this.$emit('closed');
            }
        },
    },
};
</script>
<style lang="scss">
.v-dialog--fullscreen {
    background-color: #fff;
}
</style>
