<template>
    <image-upload width="200px" height="200px" :url="profileImageUrl" :uploading="uploading" placeholder="mdi-account" v-on:upload="upload"></image-upload>
</template>

<script>
import { mapActions } from 'vuex';
import ImageUpload from '@/vendor/noop/components/ImageUpload.vue';
//import Vue from 'vue';

export default {
    name: 'ProfileImage',
    components: {
        ImageUpload,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            id: null,
            profileImageUrl: null,
            uploading: false,
        };
    },
    watch: {
        value: function (value) {
            this.value = value;
            if (this.value && this.value.profilbilde) {
                this.id = value.id;
            } else {
                this.id = null;
            }
            this.update();
        },
    },

    created: function () {
        if (this.value && this.value.profilbilde) {
            this.id = this.value.id;
        }
        this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'imageRequest']),

        /**
         * update
         */
        update: async function () {
            if (this.id) {
                this.profileImageUrl = await this.imageRequest({
                    method: 'get',
                    url: '/person/' + this.id + '/profilbilde',
                });
            } else {
                this.profileImageUrl = null;
            }
        },

        /**
         * upload
         */
        upload: async function (file) {
            const size = file.size ? file.size / (1000 * 1000) : null;
            if (size && size > 12) {
                console.log('Feil', 'Bildet er for stort (' + size.toPrecision(2) + ' MB). Det kan være maksimum 12 MB.');
            } else {
                const data = new FormData();
                data.append('image', file, file.name);

                const id = this.value.id;

                this.uploading = true;

                const response = await this.request({
                    method: 'post',
                    url: '/person/' + id + '/profilbilde',
                    data,
                });
                if (response && typeof response.id != 'undefined') {
                    this.id = response.id;
                    await this.update();
                }
                this.uploading = false;
            }
        },
    },
};
</script>
