<template>
    <v-menu v-model="picker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :label="label"
                v-model="formatted"
                persistent-hint
                append-icon="mdi-calendar"
                v-bind="attrs"
                @blur="parseDate(formatted)"
                v-on="on"
                class="shrink"
                :readonly="readonly"
                :rules="rules"
                :style="{ 'max-width': maxWidth }"
            ></v-text-field>
        </template>
        <v-date-picker v-if="!readonly" v-model="selected" v-on:change="datePicked()" no-title @input="picker = false"></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';

export default {
    name: 'DateField',
    props: {
        value: {
            default: '',
            type: moment,
        },
        label: {
            default: '',
            type: String,
        },
        maxWidth: {
            default: 'none',
            type: String,
        },
        rules: {
            default: function () {
                return [];
            },
            type: Array,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            date: null,
            formatted: null,
            selected: null,
            picker: false,
        };
    },
    watch: {
        date: function (value) {
            this.$emit('input', value);
        },
        value: function (/*value*/) {
            if (this.value) {
                if (this.date != this.value) {
                    this.date = this.value;
                    this.formatted = this.date.format('DD.MM.YYYY');
                    this.selected = this.date.format('YYYY-MM-DD');
                }
            } else {
                this.date = null;
                this.formatted = '';
                this.selected = '';
            }
        },
    },
    created: function () {
        if (this.value) {
            this.date = this.value;
            this.formatted = this.date.format('DD.MM.YYYY');
            this.selected = this.date.format('YYYY-MM-DD');
        }
    },
    methods: {
        parseDate: function (formatted) {
            try {
                if (formatted) {
                    this.date = moment(formatted, 'DD.MM.YYYY');
                    this.selected = this.date.format('YYYY-MM-DD');
                    return;
                }
            } catch (e) {
                console.log(e);
            }
            this.date = null;
            this.selected = null;
        },
        datePicked: function () {
            if (this.selected) {
                this.date = moment(this.selected, 'YYYY-MM-DD');
                this.formatted = this.date.format('DD.MM.YYYY');
            } else {
                this.date = null;
                this.formatted = null;
            }
        },
    },
};
</script>
