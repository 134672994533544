<template>
    <div>
        <div v-if="activities">
            <div
                v-for="(activity, index) in nextActivities"
                v-on:click="click(activity)"
                v-bind:class="{ 'v-card__date': true, 'v-card--link': routeTo(activity) }"
                v-bind:key="index"
            >
                <div class="grey lighten-2" v-if="!isArchived(activity)">
                    <span>{{ activity.start | format('D') }}.</span>
                    <span>{{ activity.start | format('MMM') }}</span>
                </div>
                <div class="large grey lighten-4" v-else>
                    <span>{{ activity.start | format('D') }}.</span>
                    <span>{{ activity.start | format('MMM') }}</span>
                    <span>{{ activity.start | format('YYYY') }}</span>
                </div>
                <div>
                    <span v-html="activity.tittel" style="font-weight: bold"></span>
                    <span>Når: {{ activity.start | format('dddd D. MMMM [kl. ] HH:mm') }}</span>
                    <template v-if="activity.sted || activity.moterom">
                        <span
                            >Sted:
                            <template v-if="activity.sted">{{ activity.sted }}</template>
                            <template v-if="activity.sted && activity.moterom">, </template>
                            <template v-if="activity.moterom"> {{ activity.moterom.navn }}</template>
                        </span>
                    </template>
                    <template v-if="!isArchived(activity)">
                        <template v-if="activity.paameldingsfrist">
                            <span> Påmeldingsfrist: {{ activity.paameldingsfrist | format('dddd D. MMMM') }}</span>
                        </template>
                        <!--
                        <span v-if="activity.signup == 'open' && activity.antallPlasser > 0 && activity.antallPlasser > activity.signed_up_count">
                            Maks. {{ activity.max_participants }} deltakere.
                            <template v-if="activity.signed_up_count > 0"> {{ activity.signed_up_count }} er påmeldt. </template>
                            <template v-else> Ingen påmeldte. </template>
                        </span>
                        -->
                        <!--
                        <span v-else-if="activity.signup == 'open' && activity.max_participants > 0 && activity.max_participants <= activity.signed_up_count">
                            Fullbooket ({{ activity.max_participants }} deltakere).
                        </span>
                        -->
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ShiftsList',
    props: {
        activities: {
            type: Array,
            required: true,
        },
        maximum: {
            type: Number,
            required: false,
        },
        baseRoute: {
            type: String,
            default: '/aktivitet',
        },
    },
    data: () => ({
        today: new moment(),
    }),
    computed: {
        nextActivities: function () {
            if (this.activities && this.maximum > 0 && this.activities.length > this.maximum) {
                this.activities.slice(0, this.maximum);
            }
            return this.activities;
        },
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    methods: {
        isArchived: function (activity) {
            return activity.date && activity.date.isBefore(this.today, 'day');
        },

        click: function (aktivitet) {
            console.log('click');
            const path = this.routeTo(aktivitet);
            if (path) {
                this.$router.push({ path: path });
            }
        },

        routeTo: function (aktivitet) {
            if (aktivitet) {
                if (aktivitet.type.id == 'SORGGRUPPE') {
                    return '/sorg/gruppemote/' + aktivitet.id;
                } else if (aktivitet.type.id == 'HJEMMETJENESTE') {
                    return '';
                }
                return '/arrangement/' + aktivitet.id;
            }
            return '';
        },
    },
};
</script>
