<template>
    <v-app>
        <v-snackbar color="success" app top right :timeout="snackbarTimeout" v-model="snackbarVisible">
            <div v-html="snackbarMessage"></div>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbarVisible = false">
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <div v-if="user">
            <v-navigation-drawer v-if="hasDrawer" v-model="drawer" app :clipped="true">
                <v-list dense nav>
                    <v-list-group
                        v-for="(service, handle) in services"
                        v-bind:key="handle"
                        :to="{ path: service.path }"
                        color="primary"
                        no-action
                        :prepend-icon="service.icon"
                    >
                        <template v-slot:activator>
                            <v-list-item-content :to="{ path: service.path }" v-on:click="$router.push({ path: service.path })">
                                <v-list-item-title v-text="service.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-for="serviceMenu in menu">
                            <template v-if="serviceMenu.service == handle">
                                <v-list-item v-for="(menuItem, index) in serviceMenu.items" v-bind:key="index" :to="{ path: menuItem.path }">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="menuItem.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </v-list-group>

                    <v-divider></v-divider>

                    <v-list-item to="/profil">
                        <v-list-item-action>
                            <v-avatar size="28" v-if="userImage">
                                <img :src="userImage" :alt="userFullName" />
                            </v-avatar>
                            <v-icon v-else dark>mdi-account</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ userAccount }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-on:click="logout">
                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t('btn.logout') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-app-bar app dark color="primary" :clipped-left="true">
                <v-app-bar-nav-icon v-if="hasDrawer" @click.stop="drawer = !drawer">
                    <template>
                        <v-icon>mdi-menu</v-icon>
                    </template>
                </v-app-bar-nav-icon>

                <v-toolbar-title>
                    <router-link to="/">
                        <img src="/img/logo_neg.png" />
                    </router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <template v-for="(service, handle) in services">
                    <v-btn text v-bind:key="handle" :to="service.path" v-bind:class="serviceTabClassName(handle)" class="v-btn--app d-none d-md-flex">
                        <v-icon dark>{{ service.icon }}</v-icon>
                        {{ service.name }}
                    </v-btn>
                </template>

                <v-menu v-if="user" :value="profile" bottom left :close-on-content-click="false" max-width="320">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text large class="d-none d-md-flex v-btn--profile" v-bind="attrs" @click="profile = true" v-on="on">
                            <v-avatar size="32" class="mr-2">
                                <img v-if="userImage" :src="userImage" :alt="userFullName" />
                                <v-icon v-else dark>mdi-account</v-icon>
                            </v-avatar>
                            <div class="v-btn__name">{{ userAccount }}</div>
                        </v-btn>
                    </template>
                    <v-container class="d-flex flex-column flex-wrap justify-center align-center white">
                        <router-link to="/profil" class="text-decoration-none" @click.native="profile = false">
                            <v-avatar color="grey" size="120" style="width: 120px">
                                <img v-if="userImage" :src="userImage" :alt="userFullName" />
                                <v-icon v-else x-large dark>mdi-account</v-icon>
                            </v-avatar>
                        </router-link>

                        <router-link style="padding: 10px 0" to="/profil" class="text-decoration-none" @click.native="profile = false">
                            {{ userFullName }}
                        </router-link>

                        <v-divider style="width: 100%"></v-divider>

                        <v-btn color="primary" v-on:click="logout" style="margin: 10px 0">
                            {{ $t('btn.logout') }}
                        </v-btn>
                    </v-container>
                </v-menu>

                <template v-if="!hasDrawer" v-slot:extension>
                    <v-tabs v-model="menuItem" :optional="true" class="v-tabs-bar--menu">
                        <template v-if="serviceMenu">
                            <template v-for="item in serviceMenu.items">
                                <template v-if="item.right">
                                    <v-spacer v-bind:key="item.path + '-divider'"></v-spacer>
                                </template>
                                <v-tab v-bind:key="item.path" :to="{ path: item.path }">
                                    <template v-if="item.items && item.items.length">
                                        <v-menu
                                            rounded="0"
                                            offset-y
                                            open-on-hover
                                            content-class="v-tabs__popup-menu"
                                            min-width="150"
                                            xnudge-top="-16"
                                            nudge-right="-16"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on" class="v-tabs-bar--menu__title">
                                                    {{ item.title }}
                                                </div>
                                            </template>
                                            <v-list>
                                                <v-list-item v-for="(listItem, itemIndex) in item.items" v-bind:key="itemIndex" :to="{ path: listItem.path }">
                                                    <v-list-item-title>{{ listItem.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                    <div v-else>
                                        {{ item.title }}
                                    </div>
                                </v-tab>
                            </template>
                        </template>
                    </v-tabs>
                </template>
            </v-app-bar>
            <v-main>
                <v-sheet v-if="sorgRapporter && sorgRapporter.length" color="grey lighten-2">
                    <v-container class="d-flex align-center">
                        <div class="mr-6">Det er en månedsrapport klar for levering</div>
                        <v-btn outlined v-on:click="$refs.sorgRapport.edit(0)">Lever</v-btn>
                    </v-container>
                </v-sheet>
                <v-sheet v-if="hjemmeRapporter && hjemmeRapporter.length" color="grey lighten-2">
                    <v-container class="d-flex align-center">
                        <div class="mr-6">Det er en månedsrapport klar for levering</div>
                        <v-btn outlined v-on:click="$refs.hjemmeRapport.edit(0)">Lever</v-btn>
                    </v-container>
                </v-sheet>
                <v-breadcrumbs :items="breadcrumbs" large>
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
                <div class="mx-3">
                    <router-view></router-view>
                </div>
            </v-main>
        </div>
        <alert ref="alert"></alert>
        <confirm ref="confirm"></confirm>
        <GriefReport ref="sorgRapport" :rapporter="sorgRapporter" :innlogget-bruker="true"></GriefReport>
        <VisitReport ref="hjemmeRapport" :rapporter="hjemmeRapporter" :innlogget-bruker="true"></VisitReport>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/vendor/noop/components/Alert.vue';
import Confirm from '@/vendor/noop/components/Confirm.vue';
import GriefReport from '@/pages/grief/ReportDialog.vue';
import VisitReport from '@/pages/visit/ReportDialog';

export default {
    name: 'App',
    components: {
        Alert,
        Confirm,
        GriefReport,
        VisitReport,
    },
    data: function () {
        return {
            drawer: null,
            width: window.innerWidth,

            menuItem: null,
            profile: null,

            service: null,

            userImage: '',
        };
    },
    computed: {
        ...mapGetters('authenticate', ['authenticated', 'accessToken']),
        ...mapGetters('api', ['user', 'sorgRapporter', 'hjemmeRapporter']),
        ...mapGetters({ snackbarMessage: 'snackbarMessage', snackbarTimeout: 'snackbarTimeout', alertHandler: 'alert', confirmHandler: 'confirm' }),
        ...mapGetters('site', ['services', 'menu', 'routes', 'breadcrumbs']),

        serviceMenu: function () {
            for (const menu of this.menu) {
                if (menu.service == this.service) {
                    return menu;
                }
            }
            return [];
        },

        snackbarVisible: {
            get() {
                return this.$store.getters['snackbarVisible'];
            },
            set(newValue) {
                return this.$store.dispatch('snackbarVisible', newValue);
            },
        },

        hasDrawer: function () {
            return this.width <= 960;
        },

        userFullName: function () {
            return this.user && this.user.id ? this.user.fulltNavn : '';
        },
        userAccount: function () {
            if (this.user && this.user.id) {
                if (this.width < 1264) {
                    return this.user.fulltNavn
                        .split(' ')
                        .map((n) => n[0])
                        .join('');
                }
                return this.user.fulltNavn;
            }
            return '';
        },
    },
    watch: {
        /**
         * authenticated
         */
        authenticated: function (value) {
            if (value) {
                this.me().then(async () => {
                    this.setUser(this.user).then(() => {
                        if (this.services) {
                            if (this.service && !this.services[this.service]) {
                                this.service = null;
                            }
                            if (!this.service) {
                                for (const service in this.services) {
                                    this.service = service;
                                    break;
                                }
                            }
                        }
                    });

                    if (this.user.profilbilde) {
                        this.imageRequest({
                            method: 'get',
                            url: '/person/' + this.user.id + '/profilbilde',
                        }).then((response) => {
                            this.userImage = response;
                        });
                    }
                });
            }
        },

        /**
         * route change
         */
        $route: function (to /*, from*/) {
            const toService = to.path ? to.path.split('/')[1] : '';
            let activeService = null;
            for (const service in this.services) {
                if (service === toService) {
                    activeService = service;

                    if (service == to.path.substr(1)) {
                        this.menuItem = null;
                    }
                    break;
                }
            }

            if (!activeService) {
                activeService = 'intranet';
                if (to.path == '/') {
                    this.menuItem = null;
                }
            }
            if (this.service !== activeService) {
                this.service = activeService;
            }

            this.setPage(to.path);
        },

        alertHandler: async function (alert) {
            if (alert && alert.message) {
                await this.$refs.alert.open(alert.title, alert.message);
                this.alertClosed();
            }
        },

        confirmHandler: async function (confirm) {
            if (confirm && confirm.message) {
                const result = await this.$refs.confirm.open(confirm.title, confirm.message);
                this.confirmClosed(result);
            }
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.handleResize);
    },
    created: async function () {
        this.addRoutes();
    },

    /**
     * destroy
     */
    destroy: function () {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        ...mapActions('api', ['me', 'request', 'imageRequest']),
        ...mapActions('authenticate', ['logout']),
        ...mapActions('site', ['setUser', 'setPage']),
        ...mapActions(['alert', 'alertClosed', 'confirm', 'confirmClosed']),

        /**
         * addRoutes
         */
        addRoutes: function () {
            const routes = this.routes;

            let route = {
                path: '*',
                name: 'NotFound',
                component: () => import('@/pages/404.vue'),
            };
            routes.push(route);
            this.$router.addRoutes(routes);
        },

        /**
         * servicecTabClassName
         */
        serviceTabClassName: function (handle) {
            const classes = {};
            classes['v-btn--' + handle] = true;
            /*if ((handle == 'intranet') & (this.$router.currentRoute.path == '/')) {
                classes['v-btn--active'] = true;
            }*/
            if (handle == this.service) {
                classes['v-btn--active'] = true;
            }
            return classes;
        },

        /**
         * handleResize
         */
        handleResize: function (/*event*/) {
            this.width = window.innerWidth;
        },
    },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import 'styles/app.scss';
</style>
