/*
 https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
 https://stackoverflow.com/questions/45051923/keycloak-angular-no-access-control-allow-origin-header-is-present
 https://github.com/keycloak/keycloak-quickstarts/tree/latest/applications/app-vue
 */

import moment from 'moment';
import Vue from 'vue';
import Keycloak from 'keycloak-js';

export default {
    namespaced: true,
    state: function () {
        return {
            keycloak: {
                authenticated: false,
                token: null,
            },
        };
    },
    mutations: {
        keycloak(state, keycloak) {
            state.keycloak = keycloak;
        },
    },
    actions: {
        login(context) {
            // init keycloak
            let keycloak = context.state.keycloak;
            if (!keycloak.init) {
                keycloak = Keycloak({
                    url: process.env.VUE_APP_KEYCLOAK_AUTH_URL,
                    realm: 'fransiskushjelpen',
                    clientId: 'fransiskushjelpen-service',
                    onLoad: 'login-required',
                });
            }

            /*keycloak.onTokenExpired = function () {
                const now = moment();
                Vue.$log.info(now.format('YYYY-MM-DD HH:mm:ss') + ' Keycloak expired');
            };*/

            keycloak
                .init({ onLoad: 'login-required' })
                .then((auth) => {
                    context.commit('keycloak', keycloak);

                    if (!auth) {
                        window.location.reload();
                    }

                    // token Refresh
                    setInterval(() => {
                        //const now = moment();
                        //Vue.$log.info(now.format('YYYY-MM-DD HH:mm:ss') + ' Keycloak update token');
                        keycloak
                            .updateToken(30)
                            /*.then((refreshed) => {
                                const now = moment();
                                if (refreshed) {
                                    Vue.$log.info(now.format('YYYY-MM-DD HH:mm:ss') + ' Keycloak token refreshed');
                                    Vue.$log.info(
                                        moment.unix(keycloak.tokenParsed.exp + keycloak.timeSkew).format('YYYY-MM-DD HH:mm:ss') + ' Keycloak token expires'
                                    );
                                } else {
                                    Vue.$log.info(
                                        now.format('YYYY-MM-DD HH:mm:ss') +
                                            ' Keycloak token not refreshed, valid for ' +
                                            Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
                                            ' seconds'
                                    );
                                    Vue.$log.info(
                                        moment.unix(keycloak.tokenParsed.exp + keycloak.timeSkew).format('YYYY-MM-DD HH:mm:ss') + ' Keycloak token expires'
                                    );
                                    console.log(keycloak);
                                }
                            })*/
                            .catch(() => {
                                const now = moment();
                                Vue.$log.error(now.format('YYYY-MM-DD HH:mm:ss') + ' Keycloak failed to refresh token');
                            });
                    }, 60000);
                })
                .catch((e) => {
                    Vue.$log.error('Authenticated Failed');
                    console.log(e);
                });
        },
        logout(context) {
            const keycloak = context.state.keycloak;
            const url = keycloak.endpoints.logout()
                    + '?id_token_hint=' + keycloak.idToken
                    + '&post_logout_redirect_uri=' + encodeURIComponent(process.env.VUE_APP_URL);
            location.href = url;
        },
    },
    getters: {
        authenticated(state) {
            return state.keycloak.authenticated;
        },
        accessToken(state) {
            return state.keycloak.token;
        },
        accessTokenExpires(state) {
            //console.log(state.keycloak);
            return moment.unix(state.keycloak.tokenParsed.exp + state.keycloak.timeSkew);
        },
        username(state) {
            return state.keycloak.idTokenParsed.preferred_username;
        },
        user(state) {
            return state.user;
        } /*,
        permissions(state) {
            return state.permissions;
        },*/,
    },
};
