<template>
    <v-dialog v-model="dialog" width="500" transition="dialog-top-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Månedsrapport</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <br />
                <p class="text-subtitle-1">
                    <strong>Rapport for {{ rapport.dato | format('MMMM YYYY') }}.</strong>
                </p>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field v-model="rapport.antallKoblinger" :label="antallKoblingerLabel" type="number" :readonly="readonly"></v-text-field>
                    <v-text-field
                        v-model="rapport.antallGanger"
                        label="Hvor mange ganger har du vært frivillig denne måneden?"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                        v-model="rapport.antallTimer"
                        label="Hvor mange timer har du brukt som frivillig? (Reising ikke inkludert)"
                        type="number"
                        :readonly="readonly"
                    ></v-text-field>
                    <v-textarea v-model="rapport.kommentar" label="Er det noe du ønsker å tilføye?" :readonly="readonly"></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <template v-if="!readonly">
                    <v-btn text v-on:click="dialog = false">Avbryt</v-btn>
                    <v-btn v-on:click="save" color="primary" v-text="rapport.levert ? 'Lagre' : 'Levèr'"></v-btn>
                </template>
                <v-btn v-else v-on:click="dialog = false">Lukk</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'VisitDialogReport',
    props: {
        rapporter: {
            type: Array,
            required: true,
        },
        innloggetBruker: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rapport: {},
            dialog: false,
            index: -1,
            valid: false,
            readonly: true,
        };
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
        antallKoblingerLabel: function () {
            switch (this.rapport && this.rapport.type ? this.rapport.type.navn : '') {
                case 'Besøkstjenesten':
                    return 'Hvor mange besøksvenner har du?';
                case 'Pårørendepause':
                    return 'Hvor mange hjelper du?';
                case 'Familiehjelperen':
                    return 'Hvor mange familier hjelper du?';
                //case 'Pleietjenesten':
                default:
                    return 'Hvor mange familier hjelper du?';
            }
        },
    },
    methods: {
        ...mapActions('api', ['request', 'updateReports']),
        ...mapActions(['snackbar']),

        /**
         * edit
         */
        edit: function (index) {
            this.rapport = Object.assign({}, this.rapporter[index]);
            this.readonly = false;
            if (!this.hasRole('ANSATT')) {
                if (this.rapport.levert) {
                    const daysSinceDelivered = moment().diff(this.rapport.levert, 'd');
                    if (daysSinceDelivered > 10) {
                        this.readonly = true;
                    }
                }
            }
            this.index = index;
            this.dialog = true;
        },

        /**
         * save
         */
        save: async function () {
            const rapport = Object.assign({}, this.rapport);
            const today = new moment();

            const response = await this.request({
                method: 'post',
                url: '/hjemmetjeneste/rapportskjema/' + rapport.id + '/lever',
                data: {
                    antallKoblinger: rapport.antallKoblinger,
                    antallGanger: rapport.antallGanger,
                    antallTimer: rapport.antallTimer,
                    kommentar: rapport.kommentar,
                    levert: today.format('DD.MM.YYYY'),
                },
            });

            this.dialog = false;
            this.rapporter[this.index] = response;
            this.rapporter[this.index].levert = moment();

            if (this.innloggetBruker) {
                await this.updateReports();
                this.snackbar('Månedsrapport er levert.');
            }

            this.$emit('closed');
        },
    },
};
</script>
<style lang="scss">
.v-dialog--fullscreen {
    background-color: #fff;
}
</style>
