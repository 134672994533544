import moment from 'moment';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueLogger from 'vuejs-logger';

import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import vuetify from './plugins/vuetify';

import { router } from './router';
import { store } from './store';

import { messages } from './locale/nb';
//import './service/register'

moment.locale('nb');

Vue.use(VueLogger);
Vue.config.productionTip = false;
Vue.use(VueI18n);

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const i18n = new VueI18n({
    locale: 'nb',
    messages,
});

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
