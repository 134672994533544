<template>
    <v-container>
        <v-row>
            <v-col cols="8">
                <v-card flat v-if="artikler.length > 0">
                    <v-card-title>
                        <v-icon>mdi-file-document-outline</v-icon>
                        <span>Aktuelt</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <articles-list :artikler="artikler"> </articles-list>
                    </v-card-text>
                    <v-card-actions v-if="artikler.length >= 3">
                        <v-btn to="/aktuelt" outlined text> Flere nyheter </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card flat v-if="mineAktiviteter && mineAktiviteter.length > 0">
                    <v-card-title>
                        <v-icon>mdi-calendar-check-outline</v-icon>
                        <span>Mine aktiviteter</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <activities-list :activities="mineAktiviteter"> </activities-list>
                    </v-card-text>
                </v-card>

                <v-card flat v-if="aktiviteter && aktiviteter.length > 0">
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Aktuelle arrangement</span>
                    </v-card-title>
                    <v-card-text v-if="aktiviteter && aktiviteter.length > 0" class="text--primary">
                        <activities-list :activities="aktiviteter"> </activities-list>
                    </v-card-text>
                    <v-card-actions v-if="aktiviteter && aktiviteter.length > 1">
                        <v-btn to="/arrangement" outlined text> Flere arrangementer </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import ActivitiesList from '@/components/ActivitiesList.vue';
import ArticlesList from '@/components/ArticlesList.vue';
import moment from 'moment';

export default {
    name: 'Home',
    components: {
        ActivitiesList,
        ArticlesList,
    },
    data() {
        return {
            artikler: [],

            aktiviteter: [],
            mineAktiviteter: [],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'maalgrupper']),
    },
    created: async function () {
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity', 'formatArticle']),
        /**
         * update
         */
        update: async function () {
            const maalgrupper = this.maalgrupper;
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            // aktiviteter
            let response = await this.request({
                method: 'post',
                url: '/aktivitet/sok',
                data: {
                    fra: today.format('DD.MM.YYYY HH:mm'),
                    maalgrupper: maalgrupper,
                },
            });

            if (response && Array.isArray(response)) {
                const aktiviteter = [];
                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.type.id != 'SORGGRUPPE') {
                        aktiviteter.push(aktivitet);
                    }
                }

                this.aktiviteter = aktiviteter;
            }

            // mine aktiviteter
            response = await this.request({
                method: 'get',
                url: '/person/' + this.user.id + '/aktiviteter',
            });

            if (response && Array.isArray(response)) {
                const mineAktiviteter = [];
                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.start > today) {
                        mineAktiviteter.push(aktivitet);

                        if (mineAktiviteter.length >= 5) {
                            break;
                        }
                    }
                }

                this.mineAktiviteter = mineAktiviteter;
            }

            // artikler
            const artikler = [];
            response = await this.request({
                method: 'post',
                url: '/artikkel/sok',
                data: {
                    publisert: today.format('DD.MM.YYYY HH:mm'),
                    maalgrupper: maalgrupper,
                },
            });

            if (response && Array.isArray(response)) {
                for (let artikkel of response) {
                    artikkel = await this.formatArticle(artikkel);
                    artikler.push(artikkel);
                }
            }

            this.artikler = artikler;
        },
    },
};
</script>
