export const messages = {
    nb: {
        app: {
            name: 'Fransiskushjelpen',
        },
        btn: {
            logout: 'Logg ut',
            create: 'Opprett',
            save: 'Lagre',
            ok: 'OK',
            yes: 'Ja',
            no: 'Nei',
        },

        persons: {
            ansatt: {
                list: {
                    heading: 'Ansatte',
                    new: 'Ny ansatt',
                },
                new: {
                    heading: 'Ny ansatt',
                    new: 'Ny ansatt',
                    created: 'Opprettet',
                },
            },
            ansatt_sorg: {
                list: {
                    heading: 'Ansatte sorgtjenesten',
                    new: 'Ny ansatt',
                },
                new: {
                    heading: 'Ny ansatt',
                    new: 'Ny ansatt',
                    created: 'Opprettet',
                },
            },
            ansatt_hjemme: {
                list: {
                    heading: 'Ansatte hjemmetjeneste',
                    heading_sub: 'Ansatte',
                    new: 'Ny ansatt',
                },
                new: {
                    heading: 'Ny ansatt',
                    new: 'Ny ansatt',
                    created: 'Opprettet',
                },
            },
            frivillig: {
                list: {
                    heading: 'Frivillige',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny frivillig',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
            },
            frivillig_sorg: {
                list: {
                    heading: 'Frivillige sorgtjenesten',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny frivillig',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
            },
            frivillig_hjemme: {
                list: {
                    heading: 'Frivillige hjemmetjeneste',
                    heading_sub: 'Frivillige',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny frivillig',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
            },
            frivillig_motested: {
                list: {
                    heading: 'Frivillige Møtested Oslo Øst',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny frivillig',
                    new: 'Ny frivillig',
                    created: 'Opprettet',
                },
            },
            bruker: {
                list: {
                    heading: 'Brukere',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny bruker',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
            },

            bruker_sorg: {
                list: {
                    heading: 'Brukere sorgtjenesten',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny bruker',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
            },
            bruker_hjemme: {
                list: {
                    heading: 'Brukere hjemmetjeneste',
                    heading_sub: 'Brukere',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny bruker',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
            },
            bruker_motested: {
                list: {
                    heading: 'Brukere Møtested Oslo Øst',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
                new: {
                    heading: 'Ny bruker',
                    new: 'Ny bruker',
                    created: 'Opprettet',
                },
            },
            kontakt: {
                list: {
                    heading: 'Kontakter',
                    new: 'Ny kontakt',
                },
                new: {
                    heading: 'Ny kontakt',
                    new: 'Ny kontakt',
                    created: 'Opprettet',
                },
            },
        },
    },
};
