import Vue from 'vue';
import Vuex from 'vuex';

import authenticate from './authenticate';
import site from './site';
import api from './api';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        authenticate,
        site,
        api,
    },
    state: {
        alert: {},
        confirm: {},

        showProgress: false,
        snackbarVisible: false,
        snackbarMessage: '',
        snackbarTimeout: 3000,
    },
    mutations: {
        showProgress(state, showProgress) {
            state.showProgress = showProgress;
        },
        snackbar(state, payload) {
            if (payload) {
                state.snackbarVisible = true;
                if (typeof payload.message != 'undefined') {
                    state.snackbarMessage = payload.message;
                    state.snackbarTimeout = payload.timeout;
                } else {
                    state.snackbarMessage = payload;
                }
            } else {
                state.snackbarVisible = false;
                state.snackbarMessage = '';
                state.snackbarTimeout = 3000;
            }
        },

        alert(state, payload) {
            if (payload) {
                state.alert = {
                    title: payload.title,
                    message: payload.message,
                };

                state.alert.promise = new Promise((resolve, reject) => {
                    state.alert.resolve = resolve;
                    state.alert.reject = reject;
                });
            } else {
                if (state.alert.resolve) {
                    state.alert.resolve(true);
                }
                state.alert = {};
            }
        },

        confirm(state, payload) {
            if (payload && typeof payload == 'object') {
                state.confirm = {
                    title: payload.title,
                    message: payload.message,
                };

                state.confirm.promise = new Promise((resolve, reject) => {
                    state.confirm.resolve = resolve;
                    state.confirm.reject = reject;
                });
            } else {
                if (state.confirm.resolve) {
                    state.confirm.resolve(payload);
                }
                state.confirm = {};
            }
        },
    },
    actions: {
        snackbar(context, payload) {
            context.commit('snackbar', payload);
        },
        snackbarVisible(context, payload) {
            if (!payload) {
                context.commit('snackbar', false);
            }
        },

        alert: async function (context, payload) {
            context.commit('alert', payload);
            const result = await context.state.alert.promise;
            return result;
        },
        alertClosed(context, payload) {
            context.commit('alert', payload);
        },

        confirm: async function (context, payload) {
            context.commit('confirm', payload);
            const result = await context.state.confirm.promise;
            return result;
        },
        confirmClosed(context, payload) {
            context.commit('confirm', payload);
        },
    },
    getters: {
        snackbarMessage(state) {
            return state.snackbarMessage;
        },
        snackbarTimeout(state) {
            return state.snackbarTimeout;
        },
        snackbarVisible(state) {
            return state.snackbarVisible;
        },

        alert(state) {
            return state.alert;
        },

        confirm(state) {
            return state.confirm;
        },
    },
});

// login
store.dispatch('authenticate/login');
