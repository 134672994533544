<template>
    <div class="image-upload" v-bind:style="cssStyle('div')">
        <label for="fileInput">
            <input id="fileInput" type="file" @change="selectFile" />
            <img :src="url" v-if="url" v-bind:style="cssStyle('img')" />
            <figure v-else v-bind:style="cssStyle('figure')">
                <v-icon v-if="placeholder" x-large>
                    {{ placeholder }}
                </v-icon>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                    ></path>
                </svg>
            </figure>
            <v-progress-linear v-if="uploading" indeterminate color="primary"></v-progress-linear>
            <div v-else style="height: 4px"></div>
            <span class="upload-caption">{{ url ? 'Klikk for å endre' : 'Klikk for å laste opp' }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ImageUpload',

    /**
     * data
     */
    data() {
        return {
            filename: this.value,
        };
    },
    props: {
        url: {
            default: '',
            type: String,
        },
        placeholder: {
            default: '',
            type: String,
        },
        width: {
            default: '',
            type: String,
        },
        height: {
            default: '',
            type: String,
        },
        uploading: {
            default: false,
            type: Boolean,
        },
    },
    watch: {
        filename: function (value) {
            this.filename = value;
            this.$emit('input', this.filename);
        },
    },

    /**
     * methods
     */
    methods: {
        /**
         * selectFile
         */
        selectFile: async function (e) {
            let file = e.target.files[0];
            if (file) {
                this.$emit('upload', file);
            }
        },
        cssStyle: function (tagName) {
            const style = {};
            if (this.width) {
                style.width = this.width;
            }
            if (tagName != 'div') {
                if (this.height) {
                    style.height = this.height;
                }
            }

            return style;
        },
    },
};
</script>
<style lang="scss">
.image-upload {
    label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
    }

    input[type='file'] {
        height: 1px;
        visibility: hidden;
    }

    figure {
        display: flex;
        justify-content: center;
        background: #eee;
        padding: 30px;
        border-radius: 4px;
    }

    img {
        max-width: 100%;
    }

    span {
        padding: 6px 0;
        display: flex;
        justify-content: center;
        font-size: 12px;
    }
}
</style>
