<template>
    <v-container fluid>
        <v-row v-if="user">
            <v-col cols="12">
                <h1 class="text-h4">{{ person.fulltNavn }}</h1>
            </v-col>
            <v-col cols="12">
                <v-tabs v-model="tab" class="v-tabs--content">
                    <v-tab>Personalia</v-tab>
                </v-tabs>
                <br />
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="d-flex">
                            <tab-personalia v-model="person"></tab-personalia>
                            <profile-image v-model="person" class="ml-8"></profile-image>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TabPersonalia from '@/pages/persons/TabPersonalia.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Profile',
    components: { ProfileImage, TabPersonalia },
    data() {
        return {
            person: {},
            tab: null,
        };
    },
    watch: {
        user: function (value) {
            this.person = value;
        },
    },
    computed: {
        ...mapGetters('api', ['user']),
    },
    created: async function () {
        this.tab = 1;
        this.person = this.user;
    },
};
</script>
