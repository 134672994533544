import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/pages/Home.vue';
import Profile from '@/pages/Profile.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            name: 'Home',
        },
        {
            path: '/profil',
            component: Profile,
            name: 'Profile',
        },
    ],
});
