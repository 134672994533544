<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
        <v-toolbar dark :color="options.color" dense>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card tile>
            <v-card-text v-show="!!message" v-html="'<br>' + message"> </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.native="agree()">{{ $t('btn.yes') }}</v-btn>
                <v-btn color="grey" text @click.native="cancel()">{{ $t('btn.no') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 290,
            },
        };
    },
    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
