import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import no from 'vuetify/src/locale/no.ts';

Vue.use(Vuetify);

const opts = {
    lang: {
        locales: { no },
        current: 'no',
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#5582B0',
                secondary: '#83205B',
                //secondary: config.themes.light.secondary,
                //accent: config.themes.light.accent
            },
        },
    },
};

export default new Vuetify(opts);
