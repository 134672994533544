<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="personalia.fornavn"
                            :rules="rules.fornavn"
                            label="Fornavn"
                            required
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="personalia.etternavn"
                            :rules="rules.etternavn"
                            label="Etternavn"
                            required
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="personalia.kjoenn"
                            :items="kjoennValg"
                            item-value="value"
                            item-text="label"
                            label="Kjønn"
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-field v-model="personalia.foedselsdato" label="Fødselsdato" :readonly="!hasUserRole('ANSATT')"></date-field>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="kontakt.epost" :rules="rules.epost" label="E-postadresse" :readonly="!hasUserRole('ANSATT')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="kontakt.mobil"
                            :rules="[...rules.mobil, validerMobil]"
                            label="Mobil"
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="kontakt.adresseLinje1"
                            :rules="rules.adresseLinje"
                            label="Adresse"
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="kontakt.postnr" :rules="rules.postnr" label="Postnr." :readonly="!hasUserRole('ANSATT')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="kontakt.poststed" :rules="rules.poststed" label="Sted" :readonly="!hasUserRole('ANSATT')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-select v-model="kontakt.bydel" :items="bydelValg" label="Bydel" :readonly="!hasUserRole('ANSATT')"></v-select>
                    </v-col>
                    <v-col cols="12" v-if="!hasRole('KONTAKT')">
                        <v-text-field
                            v-model="kontakt.paarorende"
                            :rules="rules.paarorende"
                            label="Pårørende"
                            :readonly="!hasUserRole('ANSATT')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="hasUserRole('ANSATT')">
            <v-btn v-if="changed" color="primary" v-on:click="save">Lagre</v-btn>
            <v-btn text v-if="changed" color="primary" v-on:click="cancel">Angre endringer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: 'TabPersonalia',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            readonly: false,

            changedPersonalia: false,
            changedContact: false,

            person: {},

            personalia: {
                fornavn: '',
                etternavn: '',
                kjoenn: '',
                foedselsdato: '',
            },

            kontakt: {
                epost: '',
                mobil: '',
                adresseLinje1: '',
                postnr: '',
                poststed: '',
                bydel: '',
                paarorende: '',
            },

            kjoennValg: [
                { value: 'MANN', label: 'Mann' },
                { value: 'KVINNE', label: 'Kvinne' },
                { value: 'ANNET', label: 'Annet' },
                { value: 'IKKE_OPPGITT', label: 'Ikke oppgitt' },
            ],

            bydelValg: [
                '',
                'Alna',
                'Bjerke',
                'Frogner',
                'Gamle Oslo',
                'Grorud',
                'Grünerløkka',
                'Nordre Aker',
                'Nordstrand',
                'Sagene',
                'St. Hanshaugen',
                'Stovner',
                'Søndre Nordstrand',
                'Ullern',
                'Vestre Aker',
                'Østensjø',
            ],

            rules: {
                fornavn: [(v) => !!v || 'Fornavn må oppgis', (v) => (v && v.length <= 255) || 'Fornavn må være mindre enn 255 tegn'],
                etternavn: [(v) => !!v || 'Etternavn må oppgis', (v) => (v && v.length <= 255) || 'Etternavn må være mindre enn 255 tegn'],
                email: [(v) => !!v || 'E-postadresse må oppgis', (v) => /.+@.+\..+/.test(v) || 'E-postadressen er ikke gyldig'],
                mobil: [(v) => /^[\d\s]*$/.test(v) || 'Ikke et gyldig mobilnummer.'],
                adresseLinje: [(v) => (v && v.length > 255 ? 'Poststed må være mindre enn 255 tegn' : true)],
                postnr: [(v) => (v && !/^\d*$/.test(v) ? 'Må være kun tall' : true)],
                poststed: [(v) => (v && v.length > 255 ? 'Poststed må være mindre enn 255 tegn' : true)],
                bydel: [(v) => (v && v.length > 255 ? 'Bydel må være mindre enn 255 tegn' : true)],
                paarorende: [(v) => (v && v.length > 255 ? 'Pårørende må være mindre enn 255 tegn' : true)],
            },
        };
    },
    computed: {
        ...mapGetters('api', ['hasUserRole']),
        changed: function () {
            return this.changedPersonalia || this.changedContact;
        },
    },
    watch: {
        value: async function (value) {
            this.person = this.value = value;
            this.update();
        },
        personalia: {
            handler: function () {
                this.changePersonalia();
            },
            deep: true,
        },
        kontakt: {
            handler: function () {
                this.changeContact();
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: function () {
        if (this.value) {
            this.person = this.value;
            this.update();
        }
    },
    methods: {
        validerMobil(value) {
            if (!value && this.person.userId) {
                return 'Mobil er påkrevd når har innlogging';
            }
            return true;
        },
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['alert', 'snackbar']),

        /**
         * update
         */
        update: function () {
            if (this.person) {
                for (const field in this.personalia) {
                    this.personalia[field] = this.person[field];
                }
                for (const field in this.kontakt) {
                    this.kontakt[field] = this.person[field];
                }
            }
            this.changedPersonalia = false;
            this.changedContact = false;
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    let person = null;

                    // personalia
                    if (this.changedPersonalia) {
                        const data = Object.assign({}, this.personalia);
                        if (data.foedselsdato) {
                            data.foedselsdato = data.foedselsdato.format('DD.MM.YYYY');
                        }
                        const response = await this.request({
                            method: 'post',
                            url: '/person/' + this.person.id + '/personalia',
                            data: data,
                        });

                        if (response && typeof response.id != 'undefined') {
                            person = response;
                        }
                    }

                    // kontakt
                    if (this.changedContact) {
                        const data = Object.assign({}, this.kontakt);
                        const response = await this.request({
                            method: 'post',
                            url: '/person/' + this.person.id + '/kontaktinfo',
                            data: data,
                        });

                        if (response && typeof response.id != 'undefined') {
                            person = response;
                        } else if (response && response.constructor.name == 'Error') {
                            const error = response.response.data.error;
                            if (error == 'PersonMedEpostFinnesAlleredeException') {
                                await this.alert({
                                    title: 'Feil',
                                    message: 'Det finnes allerede en annen person med ' + data.epost + ' som e-postadresse.',
                                });
                            } else {
                                await this.alert({ title: 'Feil', message: 'Det oppstod en ukjent feil.' });
                            }
                        }
                    }

                    // success
                    if (person) {
                        this.person = await this.formatPerson(person);
                        this.update();

                        this.$emit('input', this.person);

                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            for (const field in this.personalia) {
                this.personalia[field] = this.person[field];
            }
            for (const field in this.kontakt) {
                this.kontakt[field] = this.person[field];
            }
            this.changedPersonalia = false;
            this.changedContact = false;
        },

        /**
         * changePersonalia
         */
        changePersonalia: function () {
            for (const field in this.personalia) {
                if (this.value[field] != this.personalia[field]) {
                    this.changedPersonalia = true;
                    break;
                }
            }
        },

        /**
         * changeContact
         */
        changeContact: function () {
            for (const field in this.kontakt) {
                if (this.value[field] != this.kontakt[field]) {
                    this.changedContact = true;
                    break;
                }
            }
        },

        /**
         * hasRole
         */
        hasRole: function (rolle) {
            return this.person.roller && this.person.roller.filter((r) => r.id == rolle).length > 0;
        },
    },
};
</script>
<style lang="scss"></style>
